define("ember-mapbox-gl/components/mapbox-gl-popup", ["exports", "ember-mapbox-gl/templates/components/mapbox-gl-popup", "ember-mapbox-gl/-private/mapbox-loader"], function (_exports, _mapboxGlPopup, _mapboxLoader) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  /**
    Adds a [popup](https://www.mapbox.com/mapbox-gl-js/api/#popup) to the map.
  
    ### Properties
    - `lngLat`
      - The longitude and latitude to pin the popup at.
  
    ### Example
    ```hbs
    {{#mapbox-gl as |map|}}
      {{#map.source options=(hash
        type='geojson'
        data=(hash
          type='FeatureCollection'
          features=(array
            (hash
              type='Feature'
              geometry=(hash
                type='Point'
                coordinates=(array -96.7969879 32.7766642)
              )
            )
          )
        )) as |source|}}
        {{source.layer layer=(hash
            type='circle'
            paint=(hash circle-color='#007cbf' circle-radius=10))}}
      {{/map.source}}
  
      {{#map.popup lngLat=(array -96.7969879 32.7766642)}}
        Dallas, TX
      {{/map.popup}}
    {{/mapbox-gl}}
    ```
  
    @class MapboxGLPopup
   */
  var _default = Ember.Component.extend({
    layout: _mapboxGlPopup.default,
    tagName: '',
    map: null,
    marker: null,
    lngLat: null,
    initOptions: null,
    onClose: function onClose() {},
    init: function init() {
      this._super.apply(this, arguments);

      var _EmberGetProperties = Ember.getProperties(this, 'initOptions', 'marker'),
          initOptions = _EmberGetProperties.initOptions,
          marker = _EmberGetProperties.marker;

      this.domContent = document.createElement('div');
      this._onClose = Ember.run.bind(this, this.onClose);
      var options = Ember.assign({}, Ember.get(Ember.getOwner(this).resolveRegistration('config:environment'), 'mapbox-gl.popup'), initOptions);
      this.popup = new _mapboxLoader.default.Mapbox.Popup(options).setDOMContent(this.domContent).on('close', this._onClose);

      if (marker === null) {
        this.popup.addTo(this.map);
      } else {
        marker.setPopup(this.popup);
      }
    },
    didReceiveAttrs: function didReceiveAttrs() {
      this._super.apply(this, arguments);

      var lngLat = Ember.get(this, 'lngLat');

      if (lngLat) {
        this.popup.setLngLat(lngLat);
      }
    },
    willDestroy: function willDestroy() {
      this._super.apply(this, arguments);

      this.popup.off('close', this._onClose);
      var marker = Ember.get(this, 'marker');

      if (marker === null) {
        this.popup.remove();
      } else {
        marker.setPopup(null);
      }
    }
  });

  _exports.default = _default;
});