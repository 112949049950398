define('ember-ux-sauce/components/uxs-link-to', ['exports', 'ember-ux-sauce/templates/components/uxs-link-to', 'ember-ux-sauce/mixins/accessible', 'ember-ux-sauce/mixins/testable', 'ember-bem-sauce/mixins/bem-component'], function (exports, _uxsLinkTo, _accessible, _testable, _bemComponent) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.LinkComponent.extend(_accessible.default, _bemComponent.default, _testable.default, {
    layout: _uxsLinkTo.default,
    base: 'uxs-link'
  });
});