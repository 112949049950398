define("ember-cognito/utils/cognito-storage", ["exports"], function (exports) {
  "use strict";

  Object.defineProperty(exports, "__esModule", {
    value: true
  });

  function _classCallCheck(instance, Constructor) {
    if (!(instance instanceof Constructor)) {
      throw new TypeError("Cannot call a class as a function");
    }
  }

  var _createClass = function () {
    function defineProperties(target, props) {
      for (var i = 0; i < props.length; i++) {
        var descriptor = props[i];
        descriptor.enumerable = descriptor.enumerable || false;
        descriptor.configurable = true;
        if ("value" in descriptor) descriptor.writable = true;
        Object.defineProperty(target, descriptor.key, descriptor);
      }
    }

    return function (Constructor, protoProps, staticProps) {
      if (protoProps) defineProperties(Constructor.prototype, protoProps);
      if (staticProps) defineProperties(Constructor, staticProps);
      return Constructor;
    };
  }();

  var CognitoStorage = function () {
    function CognitoStorage() {
      var data = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};

      _classCallCheck(this, CognitoStorage);

      this.data = data;
    }

    _createClass(CognitoStorage, [{
      key: "getData",
      value: function getData() {
        return this.data;
      }
    }, {
      key: "setItem",
      value: function setItem(key, value) {
        this.data[key] = value;
        return this.data[key];
      }
    }, {
      key: "getItem",
      value: function getItem(key) {
        return Object.prototype.hasOwnProperty.call(this.data, key) ? this.data[key] : undefined;
      }
    }, {
      key: "removeItem",
      value: function removeItem(key) {
        var value = this.data[key];
        delete this.data[key];
        return value;
      }
    }, {
      key: "clear",
      value: function clear() {
        this.data = {};
        return this.data;
      }
    }]);

    return CognitoStorage;
  }();

  exports.default = CognitoStorage;
});