define('ember-cognito/utils/cognito-user', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });

  function _toConsumableArray(arr) {
    if (Array.isArray(arr)) {
      for (var i = 0, arr2 = Array(arr.length); i < arr.length; i++) {
        arr2[i] = arr[i];
      }

      return arr2;
    } else {
      return Array.from(arr);
    }
  }

  exports.default = Ember.Object.extend({
    username: Ember.computed('user', function () {
      return this.get('user').getUsername();
    }),

    _callback: function _callback(method) {
      var _this = this;

      for (var _len = arguments.length, args = Array(_len > 1 ? _len - 1 : 0), _key = 1; _key < _len; _key++) {
        args[_key - 1] = arguments[_key];
      }

      return new Ember.RSVP.Promise(function (resolve, reject) {
        try {
          var _get;

          (_get = _this.get('user'))[method].apply(_get, _toConsumableArray(args).concat([function (err, result) {
            if (err) {
              reject(err);
            } else {
              resolve(result);
            }
          }]));
        } catch (error) {
          reject(error);
        }
      }, 'cognito-user#' + method);
    },


    // Support for methods that user { onSuccess, onFailure } callback hashes
    _callbackObj: function _callbackObj(method) {
      var _this2 = this;

      for (var _len2 = arguments.length, args = Array(_len2 > 1 ? _len2 - 1 : 0), _key2 = 1; _key2 < _len2; _key2++) {
        args[_key2 - 1] = arguments[_key2];
      }

      return new Ember.RSVP.Promise(function (resolve, reject) {
        try {
          var _get2;

          (_get2 = _this2.get('user'))[method].apply(_get2, _toConsumableArray(args).concat([{
            onSuccess: resolve,
            onFailure: reject
          }]));
        } catch (error) {
          reject(error);
        }
      }, 'cognito-user#' + method);
    },
    changePassword: function changePassword(oldPassword, newPassword) {
      return this._callback('changePassword', oldPassword, newPassword);
    },
    confirmRegistration: function confirmRegistration(confirmationCode, forceAliasCreation) {
      return this._callback('confirmRegistration', confirmationCode, forceAliasCreation);
    },
    confirmPassword: function confirmPassword(verificationCode, newPassword) {
      return this._callbackObj('confirmPassword', verificationCode, newPassword);
    },
    deleteAttributes: function deleteAttributes(attributeList) {
      return this._callback('deleteAttributes', attributeList);
    },
    deleteUser: function deleteUser() {
      return this._callback('deleteUser');
    },
    forgotPassword: function forgotPassword() {
      return this._callbackObj('forgotPassword');
    },
    getAttributeVerificationCode: function getAttributeVerificationCode(attributeName) {
      return this._callbackObj('getAttributeVerificationCode', attributeName);
    },
    getSession: function getSession() {
      return this._callback('getSession');
    },
    getUserAttributes: function getUserAttributes() {
      return this._callback('getUserAttributes');
    },
    resendConfirmationCode: function resendConfirmationCode() {
      return this._callback('resendConfirmationCode');
    },
    signOut: function signOut() {
      return Ember.get(this, 'user').signOut();
    },
    updateAttributes: function updateAttributes(attributeList) {
      return this._callback('updateAttributes', attributeList);
    },
    verifyAttribute: function verifyAttribute(attributeName, confirmationCode) {
      return this._callbackObj('verifyAttribute', attributeName, confirmationCode);
    },


    // Non-AWS method
    getGroups: function getGroups() {
      return this.getSession().then(function (session) {
        var payload = session.getIdToken().payload || {};
        return payload['cognito:groups'] || [];
      });
    },


    // Non-AWS method
    getStorageData: function getStorageData() {
      return Ember.get(this, 'user').storage.getData();
    }
  });
});