define("@ember-decorators/utils/-private/class-field-descriptor", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.isFieldDescriptor = isFieldDescriptor;
  _exports.isStage2FieldDescriptor = isStage2FieldDescriptor;

  function _typeof(obj) { if (typeof Symbol === "function" && typeof Symbol.iterator === "symbol") { _typeof = function _typeof(obj) { return typeof obj; }; } else { _typeof = function _typeof(obj) { return obj && typeof Symbol === "function" && obj.constructor === Symbol && obj !== Symbol.prototype ? "symbol" : typeof obj; }; } return _typeof(obj); }

  function _slicedToArray(arr, i) { return _arrayWithHoles(arr) || _iterableToArrayLimit(arr, i) || _nonIterableRest(); }

  function _nonIterableRest() { throw new TypeError("Invalid attempt to destructure non-iterable instance"); }

  function _iterableToArrayLimit(arr, i) { var _arr = []; var _n = true; var _d = false; var _e = undefined; try { for (var _i = arr[Symbol.iterator](), _s; !(_n = (_s = _i.next()).done); _n = true) { _arr.push(_s.value); if (i && _arr.length === i) break; } } catch (err) { _d = true; _e = err; } finally { try { if (!_n && _i["return"] != null) _i["return"](); } finally { if (_d) throw _e; } } return _arr; }

  function _arrayWithHoles(arr) { if (Array.isArray(arr)) return arr; }

  var isStage1FieldDescriptor;

  if (true
  /* NEEDS_STAGE_1_DECORATORS */
  ) {
    isStage1FieldDescriptor = function isStage1FieldDescriptor(possibleDesc) {
      if (possibleDesc.length === 3) {
        var _possibleDesc = _slicedToArray(possibleDesc, 3),
            target = _possibleDesc[0],
            key = _possibleDesc[1],
            desc = _possibleDesc[2];

        return _typeof(target) === 'object' && target !== null && typeof key === 'string' && (_typeof(desc) === 'object' && desc !== null && 'enumerable' in desc && 'configurable' in desc || desc === undefined) // TS compatibility
        ;
      } else if (possibleDesc.length === 1) {
        var _possibleDesc2 = _slicedToArray(possibleDesc, 1),
            _target = _possibleDesc2[0];

        return typeof _target === 'function' && 'prototype' in _target && !_target.__isComputedDecorator;
      }

      return false;
    };
  }

  function isFieldDescriptor(possibleDesc) {
    var isDescriptor = isStage2FieldDescriptor(possibleDesc);

    if (true
    /* NEEDS_STAGE_1_DECORATORS */
    ) {
      isDescriptor = isDescriptor || isStage1FieldDescriptor(possibleDesc);
    }

    return isDescriptor;
  }

  function isStage2FieldDescriptor(possibleDesc) {
    return possibleDesc && possibleDesc.toString() === '[object Descriptor]';
  }
});