define('ember-ux-sauce/components/uxs-list-menu', ['exports', 'ember-ux-sauce/templates/components/uxs-list-menu', 'ember-ux-sauce/utils/link-to-params'], function (exports, _uxsListMenu, _linkToParams) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    // Properties
    layout: _uxsListMenu.default,
    tagName: '',
    items: Ember.computed('routes', 'model.id', function () {
      var _this = this;

      var items = [],
          routes = Ember.get(this, 'routes'),
          modelId = Ember.get(this, 'model.id');

      routes.forEach(function (route) {
        var title = Ember.get(route, 'title'),
            routeName = Ember.get(route, 'route'),
            queryParams = Ember.merge(Ember.get(_this, 'queryParams'), Ember.get(route, 'queryParams')),
            params = (0, _linkToParams.default)(title, routeName, modelId, queryParams);

        items.push({
          params: params,
          disabled: route.disabled,
          currentWhen: route.currentWhen ? route.currentWhen : routeName
        });
      });

      return items;
    }),
    // Actions
    actions: {
      selectAction: function selectAction(item) {
        var action = Ember.get(this, 'onClick');
        if (action) {
          action(item);
        }
      }
    }
  });
});