define('ember-ux-sauce/components/uxs-form-control', ['exports', 'ember-ux-sauce/mixins/testable', 'ember-bem-sauce/mixins/bem-component', 'ember-ux-sauce/templates/components/uxs-form-control'], function (exports, _testable, _bemComponent, _uxsFormControl) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend(_bemComponent.default, _testable.default, {
    // Attributes
    base: 'uxs-form__control',
    classNameBindings: ['typeClass'],
    layout: _uxsFormControl.default,
    // Computed
    typeClass: Ember.computed('base', 'type', function () {
      return Ember.get(this, 'base') + '--' + Ember.get(this, 'type');
    }),
    hasError: Ember.computed.or('hasAttrError', 'hasClientError'),
    hasAttrError: Ember.computed.bool('error'),
    hasClientError: Ember.computed.alias('hasValidationError'),
    isError: Ember.computed.oneWay('validator.isInvalid'),
    hasTextInput: Ember.computed('type', function () {
      var textInputs = Ember.A(['text', 'password', 'number', 'hidden', 'email', 'search', 'tel', 'url']);
      return textInputs.includes(Ember.get(this, 'type'));
    }),
    hasTextArea: Ember.computed.equal('type', 'textarea'),
    hasLabel: Ember.computed.bool('label'),
    hasTip: Ember.computed.bool('tip'),
    hasWarning: Ember.computed.bool('warning'),
    hasValidationError: Ember.computed('validator.isDirty', 'isError', 'didValidate', function () {
      // only show errors when user has finished editing
      return Ember.get(this, 'didValidate') && Ember.get(this, 'isError');
    }),
    hasValidationWarning: Ember.computed('validator.{isDirty,warnings.[]}', 'isWarning', 'didValidate', function () {
      // only show warnings when user has finished editing
      return Ember.get(this, 'didValidate') && Ember.get(this, 'isWarning');
    }),
    // Methods
    init: function init() {
      this.registerModifiers(['disabled', 'compact', '*style', '*size', 'hasError:error', 'hasWarning:warning', 'hasTip:tip', 'rowControl:row-control']);
      this._super.apply(this, arguments);
      this.initValidator();
      this.initModelErrors();
    },
    getDefaultProps: function getDefaultProps() {
      return {
        validateOnFocus: true
      };
    },

    /**
     * Create a one way binding to the models ember cp validation object
     **/
    initValidator: function initValidator() {
      if (Ember.get(this, 'model')) {
        var propName = Ember.get(this, 'name');
        Ember.defineProperty(this, 'validator', Ember.computed.oneWay('model.validations.attrs.' + propName));
      }
    },
    initModelErrors: function initModelErrors() {
      if (Ember.get(this, 'model')) {
        var propName = Ember.get(this, 'name');
        Ember.defineProperty(this, 'modelErrors', Ember.computed.oneWay('model.errors.' + propName));
      }
    },

    // Events
    focusIn: function focusIn() {
      if (Ember.get(this, 'validateOnFocus')) {
        Ember.set(this, 'didValidate', false);
      }
    },
    focusOut: function focusOut() {
      if (Ember.get(this, 'validateOnFocus')) {
        Ember.set(this, 'didValidate', true);
      }
    }
  });
});