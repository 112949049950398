define("ember-mapbox-gl/components/mapbox-gl-call", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  function _typeof(obj) { if (typeof Symbol === "function" && typeof Symbol.iterator === "symbol") { _typeof = function _typeof(obj) { return typeof obj; }; } else { _typeof = function _typeof(obj) { return obj && typeof Symbol === "function" && obj.constructor === Symbol && obj !== Symbol.prototype ? "symbol" : typeof obj; }; } return _typeof(obj); }

  function _toArray(arr) { return _arrayWithHoles(arr) || _iterableToArray(arr) || _nonIterableRest(); }

  function _nonIterableRest() { throw new TypeError("Invalid attempt to destructure non-iterable instance"); }

  function _iterableToArray(iter) { if (Symbol.iterator in Object(iter) || Object.prototype.toString.call(iter) === "[object Arguments]") return Array.from(iter); }

  function _arrayWithHoles(arr) { if (Array.isArray(arr)) return arr; }

  /**
   * A component used to call a specific Mapbox GL method.
   *
   * @class MapboxGLCallComponent
   */
  var MapboxGlCallComponent = Ember.Component.extend({
    tagName: '',
    obj: null,
    func: null,
    args: null,
    onResp: function onResp() {},
    params: null,
    _scheduledCall: null,
    didReceiveAttrs: function didReceiveAttrs() {
      this._super.apply(this, arguments);

      var _EmberGetProperties = Ember.getProperties(this, 'obj', 'func', 'args', 'params'),
          obj = _EmberGetProperties.obj,
          func = _EmberGetProperties.func,
          args = _EmberGetProperties.args,
          params = _EmberGetProperties.params;

      if (args === null && params !== null) {
        if (func !== null) {
          args = params;
        } else {
          var _params = _toArray(params);

          func = _params[0];
          args = _params.slice(1);
        }
      }

      (false && !(_typeof(obj) === 'object' && obj !== null) && Ember.assert('mapbox-gl-call obj is required', _typeof(obj) === 'object' && obj !== null));
      (false && !(typeof func === 'string') && Ember.assert('mapbox-gl-call func is required and must be a string', typeof func === 'string'));
      (false && !(typeof obj[func] === 'function') && Ember.assert("mapbox-gl-call ".concat(func, " must be a function on ").concat(obj), typeof obj[func] === 'function'));
      this._scheduledCall = Ember.run.scheduleOnce('afterRender', this, this._call, obj, func, args);
    },
    willDestroy: function willDestroy() {
      this._super.apply(this, arguments);

      if (this._scheduledCall !== null) {
        Ember.run.cancel(this._scheduledCall);
      }
    },
    _call: function _call(obj, func, args) {
      this._scheduledCall = null;
      this.onResp(obj[func].apply(obj, args));
    }
  });
  MapboxGlCallComponent.reopenClass({
    positionalParams: 'params'
  });
  var _default = MapboxGlCallComponent;
  _exports.default = _default;
});