define("ember-mapbox-gl/components/mapbox-gl-layer", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  /**
    Adds a data source to the map. 
    The API matches the mapbox [source docs](https://www.mapbox.com/mapbox-gl-js/api/#sources).
   
    Example:
    ```hbs
    {{#mapbox-gl as |map|}}
      {{#map.source options=(hash
        type='geojson'
        data=(hash
          type='FeatureCollection'
          features=(array
            (hash
              type='Feature'
              geometry=(hash
                type='Point'
                coordinates=(array -96.7969879 32.7766642)
              )
            )
          )
        )) as |source|}}
        {{source.layer layer=(hash
            type='circle'
            paint=(hash circle-color='#007cbf' circle-radius=10))}}
      {{/map.source}}
    {{/mapbox-gl}}
    ```
  
    @class MapboxGLSource
  */
  var _default = Ember.Component.extend({
    tagName: '',
    map: null,

    /**
      @argument layer
      @type {Object}
      @description 
      A hash to pass on to the mapbox [layer](https://www.mapbox.com/mapbox-gl-js/style-spec/#layers).
    */
    layer: null,

    /**
      @argument before
      @type {String}
      @description 
      The ID of an existing layer to insert the new layer before.
      If this argument is omitted, the layer will be appended to the end of the layers array.
    */
    before: null,

    /**
     * @property _sourceId
     * @private for use by mapbox-gl-source to pass in its sourceId
     */
    _sourceId: Ember.computed.reads('layer.source'),

    /**
     * @property _layerId
     * @private
     */
    _layerId: Ember.computed('layer.id', function () {
      return Ember.get(this, 'layer.id') || Ember.guidFor(this);
    }).readOnly(),

    /**
     * @property _layerType
     * @private
     */
    _layerType: Ember.computed('layer.type', function () {
      return Ember.get(this, 'layer.type') || 'line';
    }).readOnly(),
    _envConfig: Ember.computed('_layerType', function () {
      var layerType = Ember.get(this, '_layerType');
      return Ember.get(Ember.getOwner(this).resolveRegistration('config:environment'), "mapbox-gl.".concat(layerType));
    }).readOnly(),
    _layout: Ember.computed('_envConfig.layout', 'layer.layout', function () {
      return Ember.assign({}, Ember.get(this, '_envConfig.layout'), Ember.get(this, 'layer.layout'));
    }).readOnly(),
    _paint: Ember.computed('_envConfig.paint', 'layer.paint', function () {
      return Ember.assign({}, Ember.get(this, '_envConfig.paint'), Ember.get(this, 'layer.paint'));
    }).readOnly(),
    _layer: Ember.computed('layer', '_layerId', '_layerType', '_sourceId', '_layout', '_paint', function () {
      var _EmberGetProperties = Ember.getProperties(this, 'layer', '_layerId', '_layerType', '_sourceId', '_layout', '_paint'),
          layer = _EmberGetProperties.layer,
          _layerId = _EmberGetProperties._layerId,
          _layerType = _EmberGetProperties._layerType,
          _sourceId = _EmberGetProperties._sourceId,
          _layout = _EmberGetProperties._layout,
          _paint = _EmberGetProperties._paint;

      var computedLayer = {
        id: _layerId,
        type: _layerType,
        source: _sourceId,
        layout: _layout,
        paint: _paint
      }; // do this to pick up other properties like filter, re, metadata, source-layer, minzoom, maxzoom, etc

      return Ember.assign({}, layer, computedLayer);
    }),
    init: function init() {
      this._super.apply(this, arguments);

      var _EmberGetProperties2 = Ember.getProperties(this, '_layer', 'before'),
          _layer = _EmberGetProperties2._layer,
          before = _EmberGetProperties2.before;

      this.map.addLayer(_layer, before);
    },
    didUpdateAttrs: function didUpdateAttrs() {
      this._super.apply(this, arguments);

      var _layer = Ember.get(this, '_layer');

      for (var k in _layer.layout) {
        this.map.setLayoutProperty(_layer.id, k, _layer.layout[k]);
      }

      for (var _k in _layer.paint) {
        this.map.setPaintProperty(_layer.id, _k, _layer.paint[_k]);
      }

      if ('filter' in _layer) {
        this.map.setFilter(_layer.id, _layer.filter);
      }

      this.map.setLayerZoomRange(_layer.id, _layer.minzoom, _layer.maxzoom);
    },
    willDestroy: function willDestroy() {
      this._super.apply(this, arguments);

      this.map.removeLayer(Ember.get(this, '_layerId'));
    }
  });

  _exports.default = _default;
});