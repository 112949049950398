define('ember-cognito/authenticators/cognito', ['exports', 'amazon-cognito-identity-js', 'ember-simple-auth/authenticators/base', 'ember-cognito/utils/cognito-storage', 'ember-cognito/utils/cognito-user'], function (exports, _amazonCognitoIdentityJs, _base, _cognitoStorage, _cognitoUser) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _base.default.extend({
    cognito: Ember.inject.service(),
    poolId: Ember.computed.readOnly('cognito.poolId'),
    clientId: Ember.computed.readOnly('cognito.clientId'),
    authenticationFlowType: Ember.computed.readOnly('cognito.authenticationFlowType'),

    _stubUser: function _stubUser(user) {
      return user;
    },
    _getCurrentUser: function _getCurrentUser(data) {
      var pool = new _amazonCognitoIdentityJs.CognitoUserPool({
        UserPoolId: data.poolId,
        ClientId: data.clientId,
        Storage: new _cognitoStorage.default(data)
      });
      var user = pool.getCurrentUser();
      if (!user) {
        return null;
      }
      return _cognitoUser.default.create({ user: this._stubUser(user) });
    },
    restore: function restore(data) {
      var _this = this;

      var user = this._getCurrentUser(data);
      if (user) {
        return user.getSession().then(function (session) {
          if (session.isValid()) {
            /* eslint-disable camelcase */
            Ember.set(_this, 'cognito.user', user);
            Ember.get(_this, 'cognito').startRefreshTask(session);
            // Resolve with the new data the user set, in case
            // the session needed to be refreshed.
            var newData = user.getStorageData();
            newData.access_token = session.getIdToken().getJwtToken();
            return newData;
          } else {
            return Ember.RSVP.reject('session is invalid');
          }
        });
      }
      return Ember.RSVP.reject('no current user');
    },
    _resolveAuth: function _resolveAuth(resolve, result, _ref) {
      var pool = _ref.pool,
          user = _ref.user;

      /* eslint-disable camelcase */

      // Make sure to put the idToken in a place where the DataAdapterMixin wants it (access_token)
      // Add any data that's from the user's and pool's storage.
      var data = Ember.merge({
        access_token: result.getIdToken().getJwtToken(),
        poolId: pool.getUserPoolId(),
        clientId: pool.getClientId()
      }, pool.storage.getData());

      Ember.set(this, 'cognito.user', _cognitoUser.default.create({ user: user }));
      Ember.get(this, 'cognito').startRefreshTask(result);
      resolve(data);
    },
    _handleRefresh: function _handleRefresh() /* params */{
      var _this2 = this;

      var user = Ember.get(this, 'cognito.user');
      // Get the session, which will refresh it if necessary
      return user.getSession().then(function (session) {
        if (session.isValid()) {
          Ember.get(_this2, 'cognito').startRefreshTask(session);
          var newData = user.getStorageData();
          newData.access_token = session.getIdToken().getJwtToken();
          // newData.refreshed = new Date().toISOString();
          newData.poolId = _this2.get('poolId');
          newData.clientId = _this2.get('clientId');
          return newData;
        } else {
          return Ember.RSVP.reject('session is invalid');
        }
      });
    },
    _handleNewPasswordRequired: function _handleNewPasswordRequired(_ref2) {
      var _this3 = this;

      var state = _ref2.state,
          password = _ref2.password;

      return new Ember.RSVP.Promise(function (resolve, reject) {
        var that = _this3;
        var userAttrs = state.userAttributes;
        // the api doesn't accept these fields back
        delete userAttrs.phone_number_verified;
        delete userAttrs.email_verified;
        state.user.completeNewPasswordChallenge(password, state.userAttributes, {
          onSuccess: function onSuccess(result) {
            that._resolveAuth(resolve, result, state);
          },
          onFailure: function onFailure(err) {
            reject(err);
          }
        });
      }, 'cognito:newPasswordRequired');
    },
    _handleState: function _handleState(name, params) {
      if (name === 'refresh') {
        return this._handleRefresh(params);
      } else if (name === 'newPasswordRequired') {
        return this._handleNewPasswordRequired(params);
      } else {
        throw new Error('invalid state');
      }
    },
    authenticate: function authenticate(params) {
      var _this4 = this;

      var username = params.username,
          password = params.password,
          state = params.state;

      if (state) {
        return this._handleState(state.name, params);
      }

      return new Ember.RSVP.Promise(function (resolve, reject) {
        var that = _this4;

        var _EmberGetProperties = Ember.getProperties(_this4, 'poolId', 'clientId', 'authenticationFlowType'),
            poolId = _EmberGetProperties.poolId,
            clientId = _EmberGetProperties.clientId,
            authenticationFlowType = _EmberGetProperties.authenticationFlowType;

        var pool = new _amazonCognitoIdentityJs.CognitoUserPool({
          UserPoolId: poolId,
          ClientId: clientId,
          Storage: new _cognitoStorage.default({})
        });
        var user = _this4._stubUser(new _amazonCognitoIdentityJs.CognitoUser({ Username: username, Pool: pool, Storage: pool.storage }));
        var authDetails = new _amazonCognitoIdentityJs.AuthenticationDetails({ Username: username, Password: password });

        if (authenticationFlowType) {
          user.setAuthenticationFlowType(authenticationFlowType);
        }

        user.authenticateUser(authDetails, {
          onSuccess: function onSuccess(result) {
            that._resolveAuth(resolve, result, { pool: pool, user: user });
          },
          onFailure: function onFailure(err) {
            reject(err);
          },
          newPasswordRequired: function newPasswordRequired(userAttributes /* , requiredAttributes */) {
            // ember-simple-auth doesn't allow a "half" state like this --
            // the promise either resolves, or rejects.
            // In this case, we have to reject, because we can't let
            // ember-simple-auth think that the user is successfully
            // authenticated.
            delete userAttributes.email_verified;
            reject({
              state: {
                name: 'newPasswordRequired',
                user: user,
                userAttributes: userAttributes,
                pool: pool
              }
            });
          }
        });
      }, 'cognito:authenticate');
    },
    invalidate: function invalidate(data) {
      var user = this._getCurrentUser(data);
      if (user) {
        user.signOut();
      }
      Ember.set(this, 'cognito.user', undefined);
      return Ember.RSVP.resolve(data);
    }
  });
});