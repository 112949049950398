define("@ember-decorators/service/index", ["exports", "@ember-decorators/utils/computed"], function (_exports, _computed) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.inject = void 0;

  var _this = void 0;

  /**
    Decorator that injects a service into the object as the decorated property
  
     ```javascript
    import Component from '@ember/component';
    import { inject as service } from '@ember-decorators/service';
  
    export default class StoreInjectedComponent extends Component
      @service store;
    }
    ```
  
    @function
    @param {string} serviceName? - The name of the service to inject. If not provided, the property name will be used
    @return {Service}
  */
  var inject;
  _exports.inject = inject;

  if (false) {
    _exports.inject = inject = (0, _computed.computedDecoratorWithParams)(Ember.inject.service);
  } else {
    _exports.inject = inject = (0, _computed.computedDecoratorWithParams)(function (desc, params) {
      return Ember.inject.service.apply(_this, params);
    });
  }
});