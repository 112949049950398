define('ember-cognito/services/cognito', ['exports', 'amazon-cognito-identity-js', 'ember-cognito/utils/cognito-storage', 'ember-cognito/utils/cognito-user'], function (exports, _amazonCognitoIdentityJs, _cognitoStorage, _cognitoUser) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Service.extend({
    session: Ember.inject.service(),

    willDestroy: function willDestroy() {
      this._super.apply(this, arguments);
      this.stopRefreshTask();
    },


    // Primarily used so we can stub methods.
    _stubPool: function _stubPool(pool) {
      return pool;
    },


    /**
     * Method for signing up a user.
     *
     * @param username User's username
     * @param password Plain-text initial password entered by user.
     * @param attributeList New user attributes.
     * @param validationData Application metadata.
     */
    signUp: function signUp(username, password, attributeList, validationData) {
      var _getProperties = this.getProperties('poolId', 'clientId'),
          poolId = _getProperties.poolId,
          clientId = _getProperties.clientId;

      var pool = this._stubPool(new _amazonCognitoIdentityJs.CognitoUserPool({
        UserPoolId: poolId,
        ClientId: clientId,
        Storage: new _cognitoStorage.default({})
      }));

      return new Ember.RSVP.Promise(function (resolve, reject) {
        pool.signUp(username, password, attributeList, validationData, function (err, result) {
          if (err) {
            reject(err);
          } else {
            result.user = _cognitoUser.default.create({ user: result.user });
            resolve(result);
          }
        });
      }, 'cognito-service#signUp');
    },


    /**
     * Enable the token refresh timer.
     */
    startRefreshTask: function startRefreshTask(session) {
      if (!this.get('autoRefreshSession')) {
        return;
      }
      // Schedule a task for just past when the token expires.
      var now = Math.floor(new Date() / 1000);
      var exp = session.getIdToken().getExpiration();
      var adjusted = now - session.getClockDrift();
      var duration = (exp - adjusted) * 1000 + 100;
      this.set('_taskDuration', duration);
      this.set('task', Ember.run.later(this, 'refreshSession', duration));
    },


    /**
     * Disable the token refresh timer.
     */
    stopRefreshTask: function stopRefreshTask() {
      Ember.run.cancel(this.get('task'));
      this.set('task', undefined);
      this.set('_taskDuration', undefined);
    },
    refreshSession: function refreshSession() {
      var user = this.get('user');
      if (user) {
        return this.get('session').authenticate('authenticator:cognito', { state: { name: 'refresh' } });
      }
    }
  });
});