define('ember-ux-sauce/mixins/testable', ['exports', 'ember-copy'], function (exports, _emberCopy) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Mixin.create({
    // Attributes
    /**
      The name property is used as the value for test selectors.<br/>
      For example the foo-component would have the following selector:
      ```
      {{foo-component name="bar"}}
       [data-test-foo="bar"]
      ```
       The default is true, which adds the attribute with no value.
       If set to false the test selector is not rendered.
      @property name
      @default  true
      @type     {(boolean|string)}
      @public
    */
    name: true,
    modifier: Ember.computed('name', function () {
      if (typeof this.name === 'string') {
        return this.name;
      }
      return null;
    }),
    // Computed
    testSelectorValue: Ember.computed.alias('name'),
    /**
      By default a components base property is used as the value for test selectors.
      e.g.
      ```
      {{foo-component base="foo"}}
       [data-test-foo]
      ```
       If you want the test selector to differ you can set it's testSelectorName
      e.g
      ```
      {{foo-component base="foo" testSelectorName="bar"}}
       [data-test-bar]
      ```
       @property testSelectorName
      @default  null
      @type     {(string)}
      @public
    */
    testSelectorName: null,
    // Methods
    init: function init() {
      this._super.apply(this, arguments);
      this._defineAttributeBindings();
      // Customise the base component to add a css modifier for
      // the name of the insance
      // e.g. {{uxs-subheading "Foo" name="my-sub-sub"}}
      this.registerModifiers(['*modifier']);
    },

    /**
     * Find the test selector value for this element
     */
    _getTestSelectorName: function _getTestSelectorName() {
      var selector = this.get('testSelectorName');

      if (Ember.isEmpty(selector)) {
        selector = this.get('base');
      }

      selector = Ember.String.dasherize(selector);
      // turn BEM selectors into dasherised strings
      selector = selector.replace('--', '-');
      selector = selector.replace('__', '-');
      // remove prefix
      selector = selector.replace('uxs-', '');

      return selector;
    },

    /**
     * Add a attributeBindings computed property
     * that will add base classes
     */
    _defineAttributeBindings: function _defineAttributeBindings() {
      // Get existing bindings
      var attributeBindings = this.get('attributeBindings'),
          selector = this._getTestSelectorName();

      if (Ember.isEmpty(selector)) {
        Ember.Logger.warn('Missing testSelectorName from Testable commponent. Please define "base".');
      }

      // Create of modify attributeBindings
      if (Ember.isEmpty(attributeBindings)) {
        attributeBindings = [];
      } else {
        attributeBindings = (0, _emberCopy.copy)(attributeBindings);
      }
      // Add attribute computed property
      attributeBindings.push('testSelectorValue:data-test-' + selector);

      this.set('attributeBindings', attributeBindings);
    }
  });
});