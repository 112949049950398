define("ember-concurrency-decorators/index", ["exports", "ember-concurrency", "@ember-decorators/utils/decorator", "@ember-decorators/utils/computed", "ember-concurrency-decorators/last-value"], function (_exports, _emberConcurrency, _decorator, _computed, _lastValue) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  Object.defineProperty(_exports, "lastValue", {
    enumerable: true,
    get: function get() {
      return _lastValue.default;
    }
  });
  _exports.enqueueTaskGroup = _exports.keepLatestTaskGroup = _exports.dropTaskGroup = _exports.restartableTaskGroup = _exports.taskGroup = _exports.enqueueTask = _exports.keepLatestTask = _exports.dropTask = _exports.restartableTask = _exports.task = void 0;

  function _objectSpread(target) { for (var i = 1; i < arguments.length; i++) { var source = arguments[i] != null ? arguments[i] : {}; var ownKeys = Object.keys(source); if (typeof Object.getOwnPropertySymbols === 'function') { ownKeys = ownKeys.concat(Object.getOwnPropertySymbols(source).filter(function (sym) { return Object.getOwnPropertyDescriptor(source, sym).enumerable; })); } ownKeys.forEach(function (key) { _defineProperty(target, key, source[key]); }); } return target; }

  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

  function _slicedToArray(arr, i) { return _arrayWithHoles(arr) || _iterableToArrayLimit(arr, i) || _nonIterableRest(); }

  function _nonIterableRest() { throw new TypeError("Invalid attempt to destructure non-iterable instance"); }

  function _iterableToArrayLimit(arr, i) { var _arr = []; var _n = true; var _d = false; var _e = undefined; try { for (var _i = arr[Symbol.iterator](), _s; !(_n = (_s = _i.next()).done); _n = true) { _arr.push(_s.value); if (i && _arr.length === i) break; } } catch (err) { _d = true; _e = err; } finally { try { if (!_n && _i["return"] != null) _i["return"](); } finally { if (_d) throw _e; } } return _arr; }

  function _arrayWithHoles(arr) { if (Array.isArray(arr)) return arr; }

  function _typeof(obj) { if (typeof Symbol === "function" && typeof Symbol.iterator === "symbol") { _typeof = function _typeof(obj) { return typeof obj; }; } else { _typeof = function _typeof(obj) { return obj && typeof Symbol === "function" && obj.constructor === Symbol && obj !== Symbol.prototype ? "symbol" : typeof obj; }; } return _typeof(obj); }

  /**
   * This utility function assures compatibility with the Ember object model style
   * and initializer syntax required by Babel 6.
   *
   * For native classes using the method shorthand style (TypeScript & Babel 7),
   * this function will access the `value`. For legacy code it will get the value
   * from the initializer.
   *
   * // Ember object model
   * export default EmberObject.extend({
   *   @task
   *   someTask: function*() {}
   * });
   *
   * // Class syntax with initializers
   * export default class extends EmberObject {
   *   @task
   *   someTask = function*() {}
   * }
   *
   * @param {PropertyDescriptor} desc
   * @returns {object|null}
   * @private
   */
  function extractValue(desc) {
    if ('value' in desc.descriptor) {
      return desc.descriptor.value;
    }

    if (typeof desc.initializer === 'function') {
      var initializer = desc.initializer;
      delete desc.initializer;
      return initializer();
    }

    return null;
  }
  /**
   * Takes a `PropertyDescriptor` and turns it into an ember-concurrency
   * `TaskProperty`.
   *
   * @param {PropertyDescriptor} desc
   * @returns {TaskProperty}
   * @private
   */


  function createTaskFromDescriptor(desc) {
    (false && !(desc.descriptor.writable) && Ember.assert('ember-concurrency-decorators: Getters and setters are not supported for tasks.', desc.descriptor.writable));
    var value = extractValue(desc);
    (false && !(typeof value === 'function' || _typeof(value) === 'object' && typeof value.perform === 'function') && Ember.assert('ember-concurrency-decorators: Can only decorate a generator function as a task or an object with a generator method `perform` as an encapsulated task.', typeof value === 'function' || _typeof(value) === 'object' && typeof value.perform === 'function'));
    return (0, _emberConcurrency.task)(value);
  }
  /**
   * Takes a `PropertyDescriptor` and turns it into an ember-concurrency
   * `TaskGroupProperty`.
   *
   * @param {PropertyDescriptor} desc
   * @returns {TaskGroupProperty}
   * @private
   */


  function createTaskGroupFromDescriptor(desc) {
    (false && !(desc.descriptor.writable) && Ember.assert('ember-concurrency-decorators: Getters and setters are not supported for task groups.', desc.descriptor.writable));
    (false && !(!extractValue(desc)) && Ember.assert('ember-concurrency-decorators: Task groups can not accept values.', !extractValue(desc)));
    return (0, _emberConcurrency.taskGroup)();
  }
  /**
   * Applies the `options` provided using the chaining API on the given `task`.
   *
   * @param {object} options
   * @param {TaskProperty|TaskGroupProperty} task
   * @private
   */


  var applyOptions = function applyOptions(options, task) {
    return Object.entries(options).reduce(function (task, _ref) {
      var _ref2 = _slicedToArray(_ref, 2),
          key = _ref2[0],
          value = _ref2[1];

      (false && !(typeof task[key] === 'function') && Ember.assert("ember-concurrency-decorators: Option '".concat(key, "' is not a valid function"), typeof task[key] === 'function'));

      if (value === true) {
        return task[key]();
      }

      return task[key](value);
    }, task);
  };
  /**
   * Creates a decorator function that transforms the decorated property using the
   * given `propertyCreator` and accepts an optional user provided options hash,
   * that that will be merged with the `baseOptions`.
   *
   * @param {function} propertyCreator
   * @param {object} [baseOptions={}]
   * @private
   */


  var createDecorator = function createDecorator(propertyCreator) {
    var baseOptions = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : {};
    return (0, _decorator.decoratorWithParams)(function (desc) {
      var _ref3 = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : [],
          _ref4 = _slicedToArray(_ref3, 1),
          userOptions = _ref4[0];

      var initializer = desc.initializer;
      delete desc.initializer;
      return (0, _computed.computedDecorator)(function (desc) {
        return applyOptions(Object.assign({}, baseOptions, userOptions), propertyCreator(_objectSpread({}, desc, {
          initializer: initializer
        })));
      })(desc);
    });
  };
  /**
   * Turns the decorated generator function into a task.
   *
   * Optionally takes a hash of options that will be applied as modifiers to the
   * task. For instance `maxConcurrency`, `on`, `group` or `keepLatest`.
   *
   * ```js
   * import EmberObject from '@ember/object';
   * import { task } from 'ember-concurrency-decorators';
   *
   * class extends EmberObject {
   *   @task
   *   *plainTask() {}
   *
   *   @task({ maxConcurrency: 5, keepLatest: true, cancelOn: 'click' })
   *   *taskWithModifiers() {}
   * }
   * ```
   *
   * @function
   * @param {object?} [options={}]
   * @return {TaskProperty}
   */


  var task = createDecorator(createTaskFromDescriptor);
  /**
   * Turns the decorated generator function into a task and applies the
   * `restartable` modifier.
   *
   * Optionally takes a hash of further options that will be applied as modifiers
   * to the task.
   *
   * @function
   * @param {object?} [options={}]
   * @return {TaskProperty}
   */

  _exports.task = task;
  var restartableTask = createDecorator(createTaskFromDescriptor, {
    restartable: true
  });
  /**
   * Turns the decorated generator function into a task and applies the
   * `drop` modifier.
   *
   * Optionally takes a hash of further options that will be applied as modifiers
   * to the task.
   *
   * @function
   * @param {object?} [options={}]
   * @return {TaskProperty}
   */

  _exports.restartableTask = restartableTask;
  var dropTask = createDecorator(createTaskFromDescriptor, {
    drop: true
  });
  /**
   * Turns the decorated generator function into a task and applies the
   * `keepLatest` modifier.
   *
   * Optionally takes a hash of further options that will be applied as modifiers
   * to the task.
   *
   * @function
   * @param {object?} [options={}]
   * @return {TaskProperty}
   */

  _exports.dropTask = dropTask;
  var keepLatestTask = createDecorator(createTaskFromDescriptor, {
    keepLatest: true
  });
  /**
   * Turns the decorated generator function into a task and applies the
   * `enqueue` modifier.
   *
   * Optionally takes a hash of further options that will be applied as modifiers
   * to the task.
   *
   * @function
   * @param {object?} [options={}]
   * @return {TaskProperty}
   */

  _exports.keepLatestTask = keepLatestTask;
  var enqueueTask = createDecorator(createTaskFromDescriptor, {
    enqueue: true
  });
  /**
   * Turns the decorated property into a task group.
   *
   * Optionally takes a hash of options that will be applied as modifiers to the
   * task group. For instance `maxConcurrency` or `keepLatest`.
   *
   * ```js
   * import EmberObject from '@ember/object';
   * import { task taskGroup } from 'ember-concurrency-decorators';
   *
   * class extends EmberObject {
   *   @taskGroup({ maxConcurrency: 5 }) someTaskGroup;
   *
   *   @task({ group: 'someTaskGroup' })
   *   *someTask() {}
   *
   *   @task({ group: 'someTaskGroup' })
   *   *anotherTask() {}
   * }
   * ```
   *
   * @function
   * @param {object?} [options={}]
   * @return {TaskGroupProperty}
   */

  _exports.enqueueTask = enqueueTask;
  var taskGroup = createDecorator(createTaskGroupFromDescriptor);
  /**
   * Turns the decorated property into a task group and applies the
   * `restartable` modifier.
   *
   * Optionally takes a hash of further options that will be applied as modifiers
   * to the task group.
   *
   * @function
   * @param {object?} [options={}]
   * @return {TaskGroupProperty}
   */

  _exports.taskGroup = taskGroup;
  var restartableTaskGroup = createDecorator(createTaskGroupFromDescriptor, {
    restartable: true
  });
  /**
   * Turns the decorated property into a task group and applies the
   * `drop` modifier.
   *
   * Optionally takes a hash of further options that will be applied as modifiers
   * to the task group.
   *
   * @function
   * @param {object?} [options={}]
   * @return {TaskGroupProperty}
   */

  _exports.restartableTaskGroup = restartableTaskGroup;
  var dropTaskGroup = createDecorator(createTaskGroupFromDescriptor, {
    drop: true
  });
  /**
   * Turns the decorated property into a task group and applies the
   * `keepLatest` modifier.
   *
   * Optionally takes a hash of further options that will be applied as modifiers
   * to the task group.
   *
   * @function
   * @param {object?} [options={}]
   * @return {TaskGroupProperty}
   */

  _exports.dropTaskGroup = dropTaskGroup;
  var keepLatestTaskGroup = createDecorator(createTaskGroupFromDescriptor, {
    keepLatest: true
  });
  /**
   * Turns the decorated property into a task group and applies the
   * `enqueue` modifier.
   *
   * Optionally takes a hash of further options that will be applied as modifiers
   * to the task group.
   *
   * @function
   * @param {object?} [options={}]
   * @return {TaskGroupProperty}
   */

  _exports.keepLatestTaskGroup = keepLatestTaskGroup;
  var enqueueTaskGroup = createDecorator(createTaskGroupFromDescriptor, {
    enqueue: true
  });
  _exports.enqueueTaskGroup = enqueueTaskGroup;
});