define('ember-ux-sauce/components/uxs-sort-menu', ['exports', 'ember-ux-sauce/templates/components/uxs-sort-menu'], function (exports, _uxsSortMenu) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    layout: _uxsSortMenu.default,
    icon: 'swap_vert',
    subheading: 'Sort by',
    tagName: '',
    // Actions
    actions: {
      onItemSelect: function onItemSelect(value) {
        var action = this.onSelect;
        return action(value);
      }
    }
  });
});