define("ember-mapbox-gl/components/mapbox-gl-marker", ["exports", "ember-mapbox-gl/templates/components/mapbox-gl-marker", "ember-mapbox-gl/-private/mapbox-loader"], function (_exports, _mapboxGlMarker, _mapboxLoader) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  /**
   * A utility that brokers HTTP requests...
   *
   * @class MapboxGlMarker
   */
  var _default = Ember.Component.extend({
    layout: _mapboxGlMarker.default,
    map: null,
    initOptions: null,
    lngLat: null,
    init: function init() {
      this._super.apply(this, arguments);

      this.marker = null;
    },
    didInsertElement: function didInsertElement() {
      this._super.apply(this, arguments);

      Ember.run.scheduleOnce('afterRender', this, this._setup);
    },
    didUpdateAttrs: function didUpdateAttrs() {
      this._super.apply(this, arguments);

      if (this.marker !== null) {
        var lngLat = Ember.get(this, 'lngLat');
        (false && !(lngLat) && Ember.assert('mapbox-gl-marker requires lngLat, maybe you passed latLng?', lngLat));
        this.marker.setLngLat(lngLat);
      }
    },
    willDestroy: function willDestroy() {
      this._super.apply(this, arguments);

      if (this.marker !== null) {
        this.marker.remove();
      }
    },
    _setup: function _setup() {
      var _EmberGetProperties = Ember.getProperties(this, 'lngLat', 'initOptions'),
          lngLat = _EmberGetProperties.lngLat,
          initOptions = _EmberGetProperties.initOptions;

      (false && !(lngLat) && Ember.assert('mapbox-gl-marker requires lngLat, maybe you passed latLng?', lngLat));
      var options = Ember.assign({}, Ember.get(Ember.getOwner(this).resolveRegistration('config:environment'), 'mapbox-gl.marker'), initOptions);
      var marker = new _mapboxLoader.default.Mapbox.Marker(this.element, options).setLngLat(lngLat).addTo(this.map);
      Ember.set(this, 'marker', marker);
    }
  });

  _exports.default = _default;
});