define("ember-cli-group-by/macros/group-by-path", ["exports", "ember-cli-group-by/utils/group-by"], function (_exports, _groupBy) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.groupByPath = groupByPath;

  function _toConsumableArray(arr) { return _arrayWithoutHoles(arr) || _iterableToArray(arr) || _nonIterableSpread(); }

  function _nonIterableSpread() { throw new TypeError("Invalid attempt to spread non-iterable instance"); }

  function _iterableToArray(iter) { if (Symbol.iterator in Object(iter) || Object.prototype.toString.call(iter) === "[object Arguments]") return Array.from(iter); }

  function _arrayWithoutHoles(arr) { if (Array.isArray(arr)) { for (var i = 0, arr2 = new Array(arr.length); i < arr.length; i++) { arr2[i] = arr[i]; } return arr2; } }

  /**
   * Groups the array by nested async properties.
   *
   * @method groupByPath
   * @static
   * @param {String} dependentKey
   * @param {String} propertyPath
   * @param {Function} groupDefinition
   * @return {ComputedProperty} The grouped object.
   * @public
   */
  function groupByPath(dependentKey, propertyPath, groupDefinition) {
    var cp = new Ember.ComputedProperty(function (key) {
      var _this = this;

      // Add/remove property observers as required.
      var activeObserversMap = cp._activeObserverMap || (cp._activeObserverMap = new WeakMap());
      var activeObservers = activeObserversMap.get(this);

      if (activeObservers !== undefined) {
        activeObservers.forEach(function (args) {
          return Ember.removeObserver.apply(void 0, _toConsumableArray(args));
        });
      }

      function groupPropertyDidChange() {
        this.notifyPropertyChange(key);
      } // Unique computed property name so they do not overwrite each other.


      var chain = '_' + Ember.String.camelize("chain_".concat(dependentKey.replace('.', ' '), "_").concat(propertyPath.replace('.', ' '))); // Chain computed properties since @each only works one level deep.

      propertyPath.split('.').forEach(function (path, index, paths) {
        if (index === 0) {
          Ember.defineProperty(_this, "".concat(chain).concat(index), Ember.computed.mapBy(dependentKey, path));

          _this.get("".concat(chain).concat(index));
        } else if (index + 1 === paths.length) {
          Ember.addObserver(_this, "".concat(chain).concat(index - 1, ".@each.").concat(path), groupPropertyDidChange);
          activeObserversMap.set(_this, [[_this, "".concat(chain).concat(index - 1, ".@each.").concat(path), groupPropertyDidChange]]);
        } else {
          Ember.defineProperty(_this, "".concat(chain).concat(index), Ember.computed.mapBy("".concat(chain).concat(index - 1), path));

          _this.get("".concat(chain).concat(index));
        }
      });
      var itemsKeyIsAtThis = dependentKey === '@this';
      var items = itemsKeyIsAtThis ? this : this.get(dependentKey);

      if (!Ember.isArray(items)) {
        return Ember.Object.create({});
      }

      return (0, _groupBy.default)(items, propertyPath, groupDefinition);
    }, {
      dependentKeys: ["".concat(dependentKey, ".[]")],
      readOnly: true
    });
    cp._activeObserverMap = undefined;
    return cp;
  }
});