define("ember-mapbox-gl/components/mapbox-gl-image", ["exports", "ember-mapbox-gl/utils/noop"], function (_exports, _noop) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  /**
    Adds an image for use in the map, see [here](https://www.mapbox.com/mapbox-gl-js/api/#map#addimage).
  
    ### Properties
    - `name`
      - The unique name for the image. The name will be referenced in a source layer as the `icon-image`. Reference [layers-symbol](https://www.mapbox.com/mapbox-gl-js/style-spec/#layers-symbol) for more details. Property can also be used as the first positional parameter.
    - `image`
      - The path to your image, typically `/assets/<some_image>`. Property can also be used as the second positional parameter.
    - `width`
      - The width of the image in pixels.
    - `height`
      - The height of the image in pixels.
  
    ### Example
    ```hbs
    {{#mapbox-gl as |map|}}
      {{map.image 'cat' '/assets/cat.png' width=48 height=48}}
      {{!-- `name` and `icon-image` used as positional params above --}}
  
      {{#map.source options=(hash type='geojson' data=marker) as |source|}}
        {{source.layer layer=(hash
            type='symbol'
            layout=(hash
              icon-image='cat'
              icon-size=0.25))}}
      {{/map.source}}
    {{/mapbox-gl}}
    ```
  
    ```javascript
    import Controller from '@ember/controller';
  
    export default Controller.extend({
      marker: {
        type: 'FeatureCollection',
        features: [
          {
            type: 'Feature',
            geometry: { type: 'Point', coordinates: [ -96.7969879, 32.7766642 ] }
          }
        ]
      }
    });
    ```
  
    @class MapboxGlImageComponent
   */
  var MapboxGlImageComponent = Ember.Component.extend({
    tagName: '',
    map: null,
    name: null,
    image: null,
    options: null,
    width: null,
    height: null,
    onLoad: _noop.default,
    onError: _noop.default,
    _origName: null,
    _imageSet: false,
    isSvg: Ember.computed('name', function () {
      var image = Ember.get(this, 'image');

      if (image === null || typeof image !== 'string') {
        return false;
      }

      return /\.svg$/.test(image);
    }),
    didReceiveAttrs: function didReceiveAttrs() {
      this._super.apply(this, arguments);

      if (this._origName !== null) {
        this.map.removeImage(this._origName);
        this._origName = null;
        this._imageSet = false;
      }

      var _EmberGetProperties = Ember.getProperties(this, 'image', 'isSvg', 'width', 'height'),
          image = _EmberGetProperties.image,
          isSvg = _EmberGetProperties.isSvg,
          width = _EmberGetProperties.width,
          height = _EmberGetProperties.height;

      if (image === null) {
        return;
      }

      if (isSvg) {
        var img = new Image();

        if (width !== null) {
          img.width = width;
        }

        if (height !== null) {
          img.height = height;
        }

        img.onload = Ember.run.bind(this, this._onImage, image, null, img);
        img.onerror = Ember.run.bind(this, this._onSvgErr, image);
        img.src = image;
      } else {
        this.map.loadImage(image, Ember.run.bind(this, this._onImage, image));
      }
    },
    willDestroy: function willDestroy() {
      this._super.apply(this, arguments);

      if (this._imageSet === true) {
        this.map.removeImage(this._origName);
      }
    },
    _onImage: function _onImage(imageName, err, image) {
      if (this.isDestroyed || this.isDestroying) {
        return;
      }

      if (Ember.get(this, 'image') !== imageName) {
        // image has changed since we started loading
        return;
      }

      if (err) {
        return this.onError(err);
      }

      var _EmberGetProperties2 = Ember.getProperties(this, 'name', 'options'),
          name = _EmberGetProperties2.name,
          options = _EmberGetProperties2.options; // map#addImage doesn't like null for options, only undefined or an object


      if (options === null) {
        this.map.addImage(name, image);
      } else {
        this.map.addImage(name, image, options);
      }

      this._origName = name;
      this._imageSet = true;
      this.onLoad();
    },
    _onSvgErr: function _onSvgErr(imageName, ev) {
      var err = new Error('failed to load svg');
      err.ev = ev;

      this._onImage(imageName, err);
    }
  });
  MapboxGlImageComponent.reopenClass({
    positionalParams: ['name', 'image', 'options']
  });
  var _default = MapboxGlImageComponent;
  _exports.default = _default;
});